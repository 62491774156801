@import "~less-variables";

.button {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    font-size: inherit;
    font-weight: inherit;
    // Truncate
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: 0;
    background: none;
    gap: 7.5px;

    &:hover {
        background-color: transparent;
        border-color: transparent;
        color: @brand-primary;
    }

    &:focus {
        color: @brand-primary;
        &:active {
            outline: none;
        }
    }
}

.text {
    // Truncate
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
