@import "~less-variables";

.nestedList {
    padding-left: 20px;
}

.placeholder {
    background-color: @placeholder-bg;
    border-radius: 2px;
}

.logo,
.placeholder {
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-right: 10px;
}

.item {
    display: flex;
    align-items: center;
    width: 100%;
    border: 0;
    padding: 10px;
    font-weight: @font-weight-normal;
    background-color: transparent;
    white-space: normal;
    text-align: left;
    line-height: 1.25;

    &:active:focus {
        outline: none;
    }

    &:hover {
        background-color: @color-hover;
    }

    &:focus {
        background-color: @color-hover;
    }
}

.active {
    font-weight: @font-weight-semi;

    &,
    &:hover,
    &:focus {
        background-color: @color-blue-10;
    }
}

.empty {
    padding: 35px 0;
    text-align: center;
    font-size: 16px;
}
