// @see https://github.com/tobiasahlin/SpinKit

@import "~less-variables";
@background: @color-blue-70;
@size: 40px;

// BOUNCE
.bounce {
    width: @size;
    height: @size;
    position: relative;
    margin: @size auto;
}

.bounceChildren {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: @background;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    animation: bounce 2s infinite ease-in-out;
}

.bounceAnimation {
    animation-delay: -1s;
}

@keyframes bounce {
    0%,
    100% {
        transform: scale(0);
    }
    50% {
        transform: scale(1);
    }
}

// DOTS
.dots {
    margin: 20px auto;
    width: @size;
    display: flex;
    justify-content: space-evenly;
}

.dotsChildren {
    width: 8px;
    height: 8px;
    display: inline-block;
    background-color: @background;
    border-radius: 100%;
    animation: dots 1.4s ease-in-out 0s infinite both;
}

// SMALL DOTS
.smallDots {
    margin: 0px auto;
}

.dotsChild1 {
    animation-delay: -0.32s;
}

.dotsChild2 {
    animation-delay: -0.16s;
}

@keyframes dots {
    0%,
    80%,
    100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}
